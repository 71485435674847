<template>
    <div class="my-3">
        <div class="w-100">
            <h1 class="h3 text-center">
                Grupos de ordenes en total
                <strong class="text-primary">
                    {{ itemsNumber }}
                </strong>
            </h1>
        </div>

        <div class="d-flex flex-wrap mt-2">
            <div class="my-3 col-12">
                <!-- <orders-groups-filters v-model="query" /> TODO: filters-->
            </div>

            <div
                class="d-flex justify-content-between flex-column flex-lg-row col-12"
            >
                <div class="my-3 d-flex justify-content-around">
                    <vs-button v-show="query" relief @click="reloadItems">
                        Consultar 🔎
                    </vs-button>
                </div>

                <div class="my-3 d-flex align-items-end justify-content-center">
                    <tooltip-button
                        v-show="selected.length"
                        icon="🧹"
                        message="Limpiar selección"
                        @click="clearSelected"
                    />
                    <vs-pagination
                        v-model="currentPage"
                        :length="pagesNumber"
                        @input="listItems()"
                    />
                </div>
            </div>
        </div>

        <div v-show="showTable" class="w-100 mt-2">
            <b-table
                ref="selectableTable"
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                select-mode="multi"
                responsive
                outlined
                no-border-collapse
                hover
                selectable
                class="col-12"
                @row-selected="onRowSelected"
            >
                <template #cell(id)="data">
                    <div class="d-flex align-items-center">
                        <router-link
                            :to="`/app/orders-groups/detail/${data.item.id}`"
                            class="text-center font-weight-normal btn btn-primary p-1"
                        >
                            <span> {{ data.item.id }}</span>
                        </router-link>
                    </div>
                </template>
                <template #cell(deliveryStatusGroup)="data">
                    <small
                        class="text-center font-weight-normal"
                        :class="
                            $data.$deliveryStatusesGroupMessageClass[
                                data.item.deliveryStatusGroup
                            ]
                        "
                    >
                        {{
                            $data.$deliveryStatusesGroupCodes[
                                data.item.deliveryStatusGroup
                            ]
                        }}
                    </small>
                </template>

                <template #cell(paymentStatusGroup)="data">
                    <small
                        class="text-center"
                        :class="
                            $data.$paymentStatusGroupMessageClass[
                                data.item.paymentStatusGroup
                            ]
                        "
                    >
                        {{
                            $data.$paymentStatusesGroupCodes[
                                data.item.paymentStatusGroup
                            ]
                        }}
                    </small>
                </template>

                <template #cell(createdAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.createdAt | date }}
                    </small>
                </template>
                <template #cell(updatedAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.updatedAt | date }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-12 text-center">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import {
    deliveryStatusesGroupCodes,
    deliveryStatusesGroupMessageClass
} from "@/common/fields/delivery-status-group-codes";
import {
    paymentStatusGroupMessageClass,
    paymentStatusesGroupCodes
} from "@/common/fields/payment-status-group-codes";

import TooltipButton from "@/components/utils/TooltipButton.vue";

export default {
    name: "OrdersGroupsIndex",
    components: { TooltipButton },
    layout: "app",
    data: () => ({
        loaderInstance: null,
        itemsCurrent: [],
        $deliveryStatusesGroupCodes: deliveryStatusesGroupCodes,
        $deliveryStatusesGroupMessageClass: deliveryStatusesGroupMessageClass,
        $paymentStatusGroupMessageClass: paymentStatusGroupMessageClass,
        $paymentStatusesGroupCodes: paymentStatusesGroupCodes,
        $fields: [
            { key: "id", label: "ID", sortable: false },
            {
                key: "deliveryStatusGroup",
                label: "Estado",
                sortable: true
            },
            {
                key: "paymentStatusGroup",
                label: "Estado de pago",
                sortable: false
            },
            { key: "createdAt", label: "📆 Creación", sortable: true },
            { key: "updatedAt", label: "📆 Actualización", sortable: true }
        ],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0,
        loadedAllData: false,
        isLoadingAllData: false,
        itemsAll: [],
        selected: [],
        query: {}
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapState("orders", ["orders"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("orderGroup:List", "/app"))) return;
        this.listItems(true);
    },
    methods: {
        ...mapActions("orders", ["listOrdersGroupsPaginated"]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "radius" });
            try {
                const { items, pagination } =
                    await this.listOrdersGroupsPaginated({
                        query: {
                            complete,
                            page: this.currentPage,
                            ...this.query
                        }
                    });
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        onRowSelected(items) {
            this.selected = items;
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
            this.selected = [];
        }
    }
};
</script>
